<template>
  <div>
    <div class="row">
      <div class="col-12">
        <v-card flat>
          <v-card-title class="px-1 mx-md-3">
            <v-container fluid v-if="$vuetify.breakpoint.mobile">
              <v-row no-gutters>
                <v-col>
                  <b-btn
                    class="mx-1 mx-sm-2 px-1 p-sm-2"
                    size="sm"
                    @click="
                      isQuestion = false
                      isTest = true
                      getData()
                    "
                    :variant="isTest && !isQuestion ? 'primary' : ''"
                    >Test Sayısı
                  </b-btn>
                  <b-btn
                    class="mx-0 px-1 p-sm-2"
                    size="sm"
                    @click="
                      isQuestion = true
                      isTest = false
                      getData()
                    "
                    :variant="isQuestion && !isTest ? 'primary' : ''"
                    >Soru Sayısı
                  </b-btn>
                </v-col>
                <v-col>
                  <v-dialog
                    ref="dialog"
                    v-model="dateDialog"
                    :return-value.sync="dates"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="float-right" icon v-bind="attrs" v-on="on">
                        <v-icon> mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="dates"
                        scrollable
                        range
                        color="green lighten-1"
                        header-color="primary"
                        locale="tr"
                        min="2021-09-13"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            getData()
                            $refs.dialog.save(dates)
                          "
                        >
                          ARA
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-date-picker>
                      <v-radio-group class="mx-3" v-model="dateFilterValue" column>
                        <v-radio
                          v-for="item in dateFilters"
                          :key="item"
                          :label="item.name"
                          :value="item.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <router-link
                    :to="{
                      name: 'odev-karnesi'
                    }"
                  >
                    <b-button variant="outline-primary" size="sm" class="mt-1 mr-2">
                      ÖDEV KARNESİ</b-button
                    >
                  </router-link>
<!--                  <router-link-->
<!--                    :to="{-->
<!--                      name: 'odev-unite-konu-raporu'-->
<!--                    }"-->
<!--                  >-->
<!--                    <b-button variant="outline-primary" size="sm" class="mt-1 mx-2">-->
<!--                      ÜNİTE-KONU RAPORU</b-button-->
<!--                    >-->
<!--                  </router-link>-->
                  <router-link
                    :to="{
                      name: 'odev-kaynak-raporu'
                    }"
                  >
                    <b-button variant="outline-primary" size="sm" class="mt-1">
                      KAYNAK RAPORU</b-button
                    >
                  </router-link>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    hide-details
                    dense
                    v-model="isStacked"
                    label="Ders Dağılımlı"
                    @change="getData"
                  ></v-checkbox>
                  <b-form-radio-group
                    class="my-1"
                    v-show="isStacked"
                    v-model="hwStatus"
                    :options="hwStatuses"
                    button-variant="outline-primary"
                    buttons
                    size="sm"
                    @change="getData"
                  ></b-form-radio-group>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid v-else>
              <v-row justify="space-between">
                <v-col cols="4">
                  <b-btn
                    class="mx-0 px-1 p-sm-2"
                    size="sm"
                    @click="
                      isQuestion = true
                      isTest = false
                      getData()
                    "
                    :variant="isQuestion && !isTest ? 'primary' : ''"
                    >Soru Sayısı
                  </b-btn>
                  <b-btn
                    class="mx-1 mx-sm-2 px-1 p-sm-2"
                    size="sm"
                    @click="
                      isQuestion = false
                      isTest = true
                      getData()
                    "
                    :variant="isTest && !isQuestion ? 'primary' : ''"
                    >Test Sayısı
                  </b-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-content-end">
                  <router-link
                    :to="{
                      name: 'odev-karnesi'
                    }"
                  >
                    <b-button variant="outline-primary" size="sm" class="mt-1 mr-2">
                      ÖDEV KARNESİ</b-button
                    >
                  </router-link>
<!--                  <router-link-->
<!--                    :to="{-->
<!--                      name: 'odev-unite-konu-raporu'-->
<!--                    }"-->
<!--                  >-->
<!--                    <b-button variant="outline-primary" size="sm" class="mt-1 mx-2">-->
<!--                      ÜNİTE-KONU RAPORU</b-button-->
<!--                    >-->
<!--                  </router-link>-->
                  <router-link
                    :to="{
                      name: 'odev-kaynak-raporu'
                    }"
                  >
                    <b-button variant="outline-primary" size="sm" class="mt-1 mr-3">
                      KAYNAK RAPORU</b-button
                    >
                  </router-link>
                  <v-dialog
                    ref="dialog"
                    v-model="dateDialog"
                    :return-value.sync="dates"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="float-right" icon v-bind="attrs" v-on="on">
                        <v-icon large> mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="dates"
                        scrollable
                        range
                        color="green lighten-1"
                        header-color="primary"
                        locale="tr"
                        min="2021-09-13"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            getData()
                            $refs.dialog.save(dates)
                          "
                        >
                          ARA
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-date-picker>
                      <v-radio-group class="mx-3" v-model="dateFilterValue" column>
                        <v-radio
                          v-for="item in dateFilters"
                          :key="item"
                          :label="item.name"
                          :value="item.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <div class="row d-flex justify-content-between py-0">
              <h3 class="col card-title align-items-start flex-column mb-0">
                <span class="card-label font-weight-bolder text-dark mb-1">
                  Haftalık Ödev Grafiği</span
                >
                <br />
                <span v-if="dateFilterValue == 1" class="text-muted font-weight-bold font-size-sm"
                  >ödevin son kontrol tarihine göre</span
                >
                <span
                  v-else-if="dateFilterValue == 2"
                  class="text-muted font-weight-bold font-size-sm"
                  >ödevin verilme tarihine göre</span
                >
                <span
                  v-else-if="dateFilterValue == 3"
                  class="text-muted font-weight-bold font-size-sm"
                  >ödev yapma tarihine göre</span
                >
              </h3>
              <div v-if="!$vuetify.breakpoint.mobile" class="col d-flex justify-content-end">
                <v-checkbox
                  class="mt-0"
                  hide-details
                  v-model="isStacked"
                  label="Ders Dağılımlı"
                  @change="getData"
                ></v-checkbox>
              </div>
            </div>
            <div
              v-if="!$vuetify.breakpoint.mobile"
              v-show="isStacked"
              class="row d-flex justify-content-end"
            >
              <div class="col d-flex justify-content-end pt-0">
                <b-form-radio-group
                  v-show="isStacked"
                  v-model="hwStatus"
                  :options="hwStatuses"
                  button-variant="outline-primary"
                  buttons
                  size="sm"
                  @change="getData"
                ></b-form-radio-group>
              </div>
            </div>
            <div class="chart card-body p-0">
              <apex-chart
                v-if="shown && !loading"
                :options="chartOptions"
                :series="series"
                type="bar"
                height="400"
                :width="chartWidth"
              ></apex-chart>
              <b-spinner v-else-if="loading" variant="primary" label="Text Centered"></b-spinner>
              <b-alert v-else-if="!shown" show variant="info">Ödev Verisi Bulunamadı..</b-alert>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  name: 'student-homework-report-widget',
  data() {
    return {
      shown: false,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 400,
          width: 350,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0
          }
        },
        xaxis: {
          reversed: true,
          categories: []
        },
        fill: {
          opacity: 1
        }
      },
      series: [],
      dateDialog: false,
      dates: [new Date().toISOString().slice(0, 10), null],
      isTest: true,
      isQuestion: false,
      dateFilterValue: 1,
      dateFilters: [
        { value: 1, name: 'Son Kontrol Tarihine Göre' },
        { value: 2, name: 'Ödev Verilme Tarihine Göre' },
        { value: 3, name: 'Ödev Yapma Tarihine Göre' }
      ],
      isStacked: false,
      hwStatus: 1,
      hwStatuses: [
        { text: 'Tamamı', value: 1 },
        { text: 'Yapılanlar', value: 2 },
        { text: 'Yapılmayanlar', value: 3 }
      ],
      loading: false
    }
  },
  computed: {
    dynamicWidth() {
      if (this.isStacked) {
        return this.series.length != null ? this.series.length * 100 : 0
      } else {
        return this.series[0].data != null ? this.series[0].data.length * 100 : 0
      }
    },

    chartWidth() {
      return this.dynamicWidth < window.innerWidth ? '100%' : this.dynamicWidth
    }
  },
  watch: {
    deep: true,
    immediate: true
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.shown = false
      this.loading = true
      if (this.dates[0] && this.dates[1]) {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      var model = {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        DateFilterType: this.dateFilterValue
      }
      if (this.isStacked) {
        ApiService.setHeader()
        ApiService.post('api/Homework/reports/stacked', model)
          .then((data) => {
            var testloadlist = data.data
            if (testloadlist.length == 0) {
              this.series = []
              this.shown = false
              return
            }

            var courses = []
            for (let i = 0; i < testloadlist.length; i++) {
              const week = testloadlist[i]
              if (courses.indexOf(week.CourseName) == -1) courses.push(week.CourseName)
            }
            this.$nextTick(() => {
              this.series = []
              this.chartOptions.chart.stacked = true

              if (this.isQuestion) {
                if (this.hwStatus === 1) {
                  this.series = courses.map(function (s) {
                    return {
                      name: s,
                      data: testloadlist
                        .filter((d) => d.CourseName == s)
                        .map(function (f) {
                          return f.QuestionCount
                        })
                    }
                  })
                } else if (this.hwStatus === 2) {
                  this.series = courses.map(function (s) {
                    return {
                      name: s,
                      data: testloadlist
                        .filter((d) => d.CourseName == s)
                        .map(function (f) {
                          return f.CompletedQuestionCount
                        })
                    }
                  })
                } else {
                  this.series = courses.map(function (s) {
                    return {
                      name: s,
                      data: testloadlist
                        .filter((d) => d.CourseName == s)
                        .map(function (f) {
                          return f.QuestionCount - f.CompletedQuestionCount
                        })
                    }
                  })
                }
              } else if (this.isTest) {
                if (this.hwStatus === 1) {
                  this.series = courses.map(function (s) {
                    return {
                      name: s,
                      data: testloadlist
                        .filter((d) => d.CourseName == s)
                        .map(function (f) {
                          return f.TestCount
                        })
                    }
                  })
                } else if (this.hwStatus === 2) {
                  this.series = courses.map(function (s) {
                    return {
                      name: s,
                      data: testloadlist
                        .filter((d) => d.CourseName == s)
                        .map(function (f) {
                          return f.CompletedTestCount
                        })
                    }
                  })
                } else {
                  this.series = courses.map(function (s) {
                    return {
                      name: s,
                      data: testloadlist
                        .filter((d) => d.CourseName == s)
                        .map(function (f) {
                          return f.TestCount - f.CompletedTestCount
                        })
                    }
                  })
                }
              }

              this.chartOptions.xaxis.categories = this.uniqueArray(
                testloadlist.map(function (f) {
                  return (
                    moment(f.WeekStart).locale('tr').format('Do MMMM') +
                    '-' +
                    moment(f.WeekEnd).locale('tr').format('Do MMMM')
                  )
                })
              )
              this.chartOptions.chart.width = this.chartWidth

              this.shown = true
              this.loading = false
            })
          })
          .catch(({ response }) => {
            ApiService.showError(response)
          })
      } else {
        ApiService.setHeader()
        ApiService.post('api/Homework/reports/student', model)
          .then((data) => {
            var testloadlist = data.data
            if (testloadlist.length == 0) {
              this.series = []
              this.shown = false
              return
            }
            this.$nextTick(() => {
              this.series = []
              this.chartOptions.chart.stacked = false

              if (this.isQuestion) {
                this.series.push({
                  name: 'Ödevlerim',
                  data: testloadlist.map(function (f) {
                    return f.QuestionCount
                  })
                })

                this.series.push({
                  name: 'Yapılanlar',
                  data: testloadlist.map(function (f) {
                    return f.CompletedQuestionCount
                  })
                })
                this.series.push({
                  name: 'Kalanlar',
                  data: testloadlist.map(function (f) {
                    return f.QuestionCount - f.CompletedQuestionCount
                  })
                })
              } else if (this.isTest) {
                this.series.push({
                  name: 'Ödevlerim',
                  data: testloadlist.map(function (f) {
                    return f.TestCount
                  })
                })
                this.series.push({
                  name: 'Yapılanlar',
                  data: testloadlist.map(function (f) {
                    return f.CompletedTestCount
                  })
                })
                this.series.push({
                  name: 'Kalanlar',
                  data: testloadlist.map(function (f) {
                    return f.TestCount - f.CompletedTestCount
                  })
                })
              }
              this.chartOptions.chart.width = this.chartWidth
              this.chartOptions.xaxis.categories = testloadlist.map(function (f) {
                return (
                  moment(f.WeekStart).locale('tr').format('Do MMMM') +
                  '-' +
                  moment(f.WeekEnd).locale('tr').format('Do MMMM')
                )
              })
              this.shown = true
              this.loading = false
            })
          })
          .catch(({ response }) => {
            ApiService.showError(response)
          })
      }
    },
    uniqueArray(ar) {
      var j = {}

      ar.forEach(function (v) {
        j[v + '::' + typeof v] = v
      })

      return Object.keys(j).map(function (v) {
        return j[v]
      })
    },

    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({ key: v, values: [x] })
        }
        return rv
      }, [])
    }
  }
}
</script>

<style>
.chart {
  overflow: auto;
}
</style>
