var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "px-1 mx-md-3" },
                [
                  _vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-1 mx-sm-2 px-1 p-sm-2",
                                      attrs: {
                                        size: "sm",
                                        variant:
                                          _vm.isTest && !_vm.isQuestion
                                            ? "primary"
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isQuestion = false
                                          _vm.isTest = true
                                          _vm.getData()
                                        },
                                      },
                                    },
                                    [_vm._v("Test Sayısı ")]
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-0 px-1 p-sm-2",
                                      attrs: {
                                        size: "sm",
                                        variant:
                                          _vm.isQuestion && !_vm.isTest
                                            ? "primary"
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isQuestion = true
                                          _vm.isTest = false
                                          _vm.getData()
                                        },
                                      },
                                    },
                                    [_vm._v("Soru Sayısı ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialog",
                                      attrs: {
                                        "return-value": _vm.dates,
                                        persistent: "",
                                        width: "290px",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.dates = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.dates = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "float-right",
                                                        attrs: { icon: "" },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(" mdi-calendar"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3246889226
                                      ),
                                      model: {
                                        value: _vm.dateDialog,
                                        callback: function ($$v) {
                                          _vm.dateDialog = $$v
                                        },
                                        expression: "dateDialog",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                "first-day-of-week": "1",
                                                scrollable: "",
                                                range: "",
                                                color: "green lighten-1",
                                                "header-color": "primary",
                                                locale: "tr",
                                                min: "2021-09-13",
                                              },
                                              model: {
                                                value: _vm.dates,
                                                callback: function ($$v) {
                                                  _vm.dates = $$v
                                                },
                                                expression: "dates",
                                              },
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.dateDialog = false
                                                    },
                                                  },
                                                },
                                                [_vm._v(" İPTAL")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.getData()
                                                      _vm.$refs.dialog.save(
                                                        _vm.dates
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" ARA ")]
                                              ),
                                              _c("v-spacer"),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mx-3",
                                              attrs: { column: "" },
                                              model: {
                                                value: _vm.dateFilterValue,
                                                callback: function ($$v) {
                                                  _vm.dateFilterValue = $$v
                                                },
                                                expression: "dateFilterValue",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dateFilters,
                                              function (item) {
                                                return _c("v-radio", {
                                                  key: item,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "odev-karnesi",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1 mr-2",
                                          attrs: {
                                            variant: "outline-primary",
                                            size: "sm",
                                          },
                                        },
                                        [_vm._v(" ÖDEV KARNESİ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "odev-kaynak-raporu",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1",
                                          attrs: {
                                            variant: "outline-primary",
                                            size: "sm",
                                          },
                                        },
                                        [_vm._v(" KAYNAK RAPORU")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      label: "Ders Dağılımlı",
                                    },
                                    on: { change: _vm.getData },
                                    model: {
                                      value: _vm.isStacked,
                                      callback: function ($$v) {
                                        _vm.isStacked = $$v
                                      },
                                      expression: "isStacked",
                                    },
                                  }),
                                  _c("b-form-radio-group", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isStacked,
                                        expression: "isStacked",
                                      },
                                    ],
                                    staticClass: "my-1",
                                    attrs: {
                                      options: _vm.hwStatuses,
                                      "button-variant": "outline-primary",
                                      buttons: "",
                                      size: "sm",
                                    },
                                    on: { change: _vm.getData },
                                    model: {
                                      value: _vm.hwStatus,
                                      callback: function ($$v) {
                                        _vm.hwStatus = $$v
                                      },
                                      expression: "hwStatus",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "space-between" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-0 px-1 p-sm-2",
                                      attrs: {
                                        size: "sm",
                                        variant:
                                          _vm.isQuestion && !_vm.isTest
                                            ? "primary"
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isQuestion = true
                                          _vm.isTest = false
                                          _vm.getData()
                                        },
                                      },
                                    },
                                    [_vm._v("Soru Sayısı ")]
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-1 mx-sm-2 px-1 p-sm-2",
                                      attrs: {
                                        size: "sm",
                                        variant:
                                          _vm.isTest && !_vm.isQuestion
                                            ? "primary"
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isQuestion = false
                                          _vm.isTest = true
                                          _vm.getData()
                                        },
                                      },
                                    },
                                    [_vm._v("Test Sayısı ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-content-end",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "odev-karnesi",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1 mr-2",
                                          attrs: {
                                            variant: "outline-primary",
                                            size: "sm",
                                          },
                                        },
                                        [_vm._v(" ÖDEV KARNESİ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "odev-kaynak-raporu",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1 mr-3",
                                          attrs: {
                                            variant: "outline-primary",
                                            size: "sm",
                                          },
                                        },
                                        [_vm._v(" KAYNAK RAPORU")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialog",
                                      attrs: {
                                        "return-value": _vm.dates,
                                        persistent: "",
                                        width: "290px",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.dates = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.dates = $event
                                        },
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                      attrs: { icon: "" },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { large: "" } },
                                                    [_vm._v(" mdi-calendar")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.dateDialog,
                                        callback: function ($$v) {
                                          _vm.dateDialog = $$v
                                        },
                                        expression: "dateDialog",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                "first-day-of-week": "1",
                                                scrollable: "",
                                                range: "",
                                                color: "green lighten-1",
                                                "header-color": "primary",
                                                locale: "tr",
                                                min: "2021-09-13",
                                              },
                                              model: {
                                                value: _vm.dates,
                                                callback: function ($$v) {
                                                  _vm.dates = $$v
                                                },
                                                expression: "dates",
                                              },
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.dateDialog = false
                                                    },
                                                  },
                                                },
                                                [_vm._v(" İPTAL")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.getData()
                                                      _vm.$refs.dialog.save(
                                                        _vm.dates
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" ARA ")]
                                              ),
                                              _c("v-spacer"),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mx-3",
                                              attrs: { column: "" },
                                              model: {
                                                value: _vm.dateFilterValue,
                                                callback: function ($$v) {
                                                  _vm.dateFilterValue = $$v
                                                },
                                                expression: "dateFilterValue",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dateFilters,
                                              function (item) {
                                                return _c("v-radio", {
                                                  key: item,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-row"),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "row d-flex justify-content-between py-0" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "col card-title align-items-start flex-column mb-0",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "card-label font-weight-bolder text-dark mb-1",
                          },
                          [_vm._v(" Haftalık Ödev Grafiği")]
                        ),
                        _c("br"),
                        _vm.dateFilterValue == 1
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-muted font-weight-bold font-size-sm",
                              },
                              [_vm._v("ödevin son kontrol tarihine göre")]
                            )
                          : _vm.dateFilterValue == 2
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-muted font-weight-bold font-size-sm",
                              },
                              [_vm._v("ödevin verilme tarihine göre")]
                            )
                          : _vm.dateFilterValue == 3
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-muted font-weight-bold font-size-sm",
                              },
                              [_vm._v("ödev yapma tarihine göre")]
                            )
                          : _vm._e(),
                      ]
                    ),
                    !_vm.$vuetify.breakpoint.mobile
                      ? _c(
                          "div",
                          { staticClass: "col d-flex justify-content-end" },
                          [
                            _c("v-checkbox", {
                              staticClass: "mt-0",
                              attrs: {
                                "hide-details": "",
                                label: "Ders Dağılımlı",
                              },
                              on: { change: _vm.getData },
                              model: {
                                value: _vm.isStacked,
                                callback: function ($$v) {
                                  _vm.isStacked = $$v
                                },
                                expression: "isStacked",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                !_vm.$vuetify.breakpoint.mobile
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isStacked,
                            expression: "isStacked",
                          },
                        ],
                        staticClass: "row d-flex justify-content-end",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col d-flex justify-content-end pt-0",
                          },
                          [
                            _c("b-form-radio-group", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isStacked,
                                  expression: "isStacked",
                                },
                              ],
                              attrs: {
                                options: _vm.hwStatuses,
                                "button-variant": "outline-primary",
                                buttons: "",
                                size: "sm",
                              },
                              on: { change: _vm.getData },
                              model: {
                                value: _vm.hwStatus,
                                callback: function ($$v) {
                                  _vm.hwStatus = $$v
                                },
                                expression: "hwStatus",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "chart card-body p-0" },
                  [
                    _vm.shown && !_vm.loading
                      ? _c("apex-chart", {
                          attrs: {
                            options: _vm.chartOptions,
                            series: _vm.series,
                            type: "bar",
                            height: "400",
                            width: _vm.chartWidth,
                          },
                        })
                      : _vm.loading
                      ? _c("b-spinner", {
                          attrs: { variant: "primary", label: "Text Centered" },
                        })
                      : !_vm.shown
                      ? _c(
                          "b-alert",
                          { attrs: { show: "", variant: "info" } },
                          [_vm._v("Ödev Verisi Bulunamadı..")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }